import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import SadWorm from '../img/sad-worm.png';
import {Helmet} from "react-helmet";

class ErrorPage extends React.Component {

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('errorPageTitle')}</title>
                </Helmet>
                <div className="container container-high">
                <div className="col-md-6">
                    <h2 className="raide"><Translate value='errorPageText' /></h2>
                    <img src={SadWorm} className={'img-responsive error-worm'} alt={'Sad ekovormas worm'} />
                </div>
            </div>
            </React.Fragment>
        )
    }
}


export default ErrorPage
