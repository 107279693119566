export const translations = {
  lt: {
    // home
    KasYraBiohumusas: 'Kas yra Biohumusas?',
    Biohumusas: 'Biohumusas',
    biohumusasTaiIsOrganiniu: 'Biohumusas, tai visiškai ekologiškos, natūralios iš organinių medžiagų ir jų atliekų (mėšlo, pjuvenų, drožlių, lapų, žolės) Kalifornijos sliekų perdirbtos trąšos.',
    biohumusasYraKertuEfektyvesnis: 'Biohumusas yra 10 – 15 kartų efektyvesnis nei bet kokias kitos organinės trąšas. Lyginant su mėšlu, jame yra iki 100 kartų daugiau naudingųjų bakterijų, kurios atstato dirvos derlingumą. Priešingai nei mineralinės trąšos jis nealina dirvožemio. ',
    biohumusasPraturtinaDirvosStruktura: 'Dėl savo savybių biohumusas yra laikomas viena efektyviausių organinių trąšų. Jis sumažina dirvoje esančių nitratų, azoto junginių koncentracijos lygį, padeda atsigauti nualintai dirvai, sumažėja piktžolių kiekis. Jo dėka pagyvėja dirvožemio mikrobiologinis aktyvumas, didėja kultūrinių augalų atsparumas šalnoms, sausrai, taip pat neigiamiems aplinkos veiksniams ar mechaniniam poveikiui. Dėl neutralaus biohumuso rūgštingumo sustiprėja augalų imunitetas ligoms, jų sukelėjams. Priešingai nei įprastas kompostas biohumusas turi daugiau peptidų, tai leidžia biohumusui pasižymėti geresnėmis antigrybelinėmis ir antibakterinėmis savybėmis. Jo efektyvumas naikinant kenkėjus išlieka net iki 6 mėnesių. ',
    biohumusasNeturiNemalonausKvapo: 'Savo sudėtyje biohumusas turi natūralių augalų augimo ir vystymosi hormonų, fermentų, huminių medžiagų bei naudingos mikrofloros. Tai palengvina augalų įsišaknijimą, prigijimą ir paspartina jų vystymąsi. Smėlingame ir molingame dirvožemyje biohumuso pagalba galima geriau kontroliuoti ir palaikyti augalams reikalingą drėgmės pusiausvyrą. Lyginant su kitomis trąšomis biohumuse žymiai daugiau šaknų augimą skatinančių medžiagų - heteroauksinų. Dėl to yra aktyvesnis daržovių daigų, augalų sėklų prigijimas, sodinukai ir kiti persodinti augalai greičiau ir kokybiškiau prigyja. Tai viena iš priežasčių kurios dėka derlius gali sunokti ne tik 2-3 savaitėmis anksčiau, tačiau bus kokybiškesnis, pasižymės geresnėmis maistinėmis bei skonio savybėmis. ',
    biohumusasNaudojamasZemesUkio: 'Kiekvienas ūkininkas žino, jog naudojamos trąšos privalo turėti pakankamai geležies, vario, cinko, mangano, boro, kobalto, molibdeno - biohumusas pasižymi visų šių elementų gausa. Dėl išvardintų priežasčių, priklausomai nuo augalo rūšies, gautas derlius bus nuo 15% iki 50% gausesnis. ',
    bioAtvirameGrunteGalima: 'Ekovormas biohumusas puikiai tinka ekologiškam dekoratyvinių augalų, įvairių daržovių auginimui, žemės ūkio kultūrų sodinimui, tręšimui ir dirvos regeneravimui. Produktas ne tik natūralus, bet ir neturi nemalonaus kvapo. Dėl to jis yra tinkamas naudoti namuose, soduose, taip pat tai labai svarbu turintiems ūkius netoli gyvenamųjų teritorijų. Biohumusas atvirame grunte gali būti naudojamas nuo ankstyvo pavasario iki vėlyvo rudens. ',
    issilaikoNetKetSep: 'Produkto savybės ir efektyvumas išlieka net 4-7 metus! ',
    nereikesNiekoKito: 'Naudojant biohumusą nereikės jokių kitų organinių ar cheminių trąšų.',

    skaitytiDaugiau: 'Skaityti daugiau...',
    bioNaudojimasGarantuoja: 'Biohumusas suteikia:',
    greitesniSekluPrigijima: 'Dirvos derlingumo pagyvėjimą ir atstatymą (net sumaišytas su smėliu biohumusas sudaro derlingą dirvą);',
    geresneDirvosStrukt: 'Stipresnį augalų imunitetą bei apsaugą nuo chlorozės ir kitų fiziologinių ligų;',
    ankstesniDerliausSubrendima: 'Greitesnį sėklų sudygimą bei spartesnį daigų prigijimą;',
    augaluAtsparumaTemp: 'Ankstyvesnį derliaus subrendimą ir ilgesnį jo išsilaikymą;',
    gausesniIrSveikesni: 'Augalų atsparumą temperatūros pokyčiams;',
    stipresniAugaluImuniteta: 'Geresnę dirvos ir šaknų struktūrą;',
    dirvosDerlingumoAtstatyma: 'Dirvožemio mikrobiologinį aktyvumą;',
    dirvozemioMikroAktyvumas: 'Gausesnį ir sveikesnį derlių;',
    // our production (for sale)
    musuProdukcija: 'Taip pat parduodame',
    gaminameBiohumusoMis: 'Biohumuso mišinius',
    gaminameKomposta: 'Kompostą',
    galimeFasuotaIrNe: 'Nefasuotą biohumusą',
    parduodameSliekus: 'Kalifornijos sliekus',
    mesParduodam: 'Parduodamas biohumusas, universalus mišinys',
    liters: 'Litrų',
    uzsisakyti: 'Užsisakyti',
    kuboKaina: 'Sutartinė',
    buyVermicompost: 'biohumuso',
    buyUniversalMixture: 'universalaus mišinio',
    // about
    kasMesEsam: 'Kas mes esame?',
    ekovormasBuvoIkurtas: 'UAB „Ekovormas“ buvo įkurta 2016 metais Kretingos rajone. Pagrindinė mūsų įmonės veikla - gaminti biohumusą ir kompostą, auginti kalifornijos sliekus.',
    musuMisija: 'Mūsų misija',
    musuMisijaYra: 'Mūsų misija yra skatinti ekologinį ūkininkavimą gaminant ir platinant natūralias organines biologiškai aktyvias augalų vystymąsi stimuliuojančias priemones bei pateikiant patikimą, mokslo tyrimais pagrįstą informaciją apie teigiamą ekologinio ūkininkavimo įtaką aplinkai ir žmonių sveikatai; diegti praktikoje inovatyvius, mokslu pagrįstus ekologinio ūkininkavimo metodus.',
    musuVizija: 'Mūsų vizija',
    taptiPagrEfektyv: 'Tapti pagrindiniu efektyvių, natūralių organinių biologiškai aktyvių augalų vystymąsi stimuliuojančių ir aplinką bei žmonių sveikatą tausojančių priemonių tiekėju rinkoje.',
    isvystytiEfektyviusMosklu: 'Išvystyti efektyvius, mokslu pagrįstus ekologinio ūkininkavimo metodus ir užtikrinti sklandų jų diegimą praktikoje.',
    taptiIssamiuPatikimos: 'Tapti išsamiu patikimos ekologinio ūkininkavimo informacijos šaltiniu.',
    musutikslas: 'Mūsų tikslas',
    musutikslasYra: 'Mūsų tikslas yra skatinti ekologinį ūkininkavimą gaminant ir platinant natūralias organines biologiškai aktyvias augalų vystymąsi stimuliuojančias priemones bei pateikti informaciją apie teigiamą ekologinio ūkininkavimo įtaką aplinkai ir žmonių sveikatai.',
    // Documentai
    ikeltiDokumentus: 'Dokumentų įkėlimas',
    Dokumentai: 'Aktuali informacija',
    wrongPass: 'Neteisingas slaptažodis.',
    uploadSorry: 'Įvyko klaida ',
    uploadError: 'įvyko nenumatyta klaida ',
    fillFields: 'Įveskite slaptažodį ir pasirinkite failą.',
    typeErr: 'Šis failo tipas neleistinas ',
    tooLarge: 'Failo dydis per didelis.',
    uploacSuccess: 'failas buvo įkeltas.',
    uploadSuccessGreet: 'Sveikiname ',
    fileExists: 'Documentas tokiu pavadinimu jau yra įkeltas.',
    noFilesFound: 'Šiuo metu įkeltų dokumentų nėra',
    docSubmit: 'Įkelti',
    File: 'Failas',
    Password: 'Slaptažodis',
    // kontaktai
    CentralLithuania: 'Vidurio Lietuva',
    TikBiohumusas: 'tik biohumusas',
    Zemaitija: 'Žemaitija',
    BiohumusasIrSliekai: 'biohumusas ir sliekai',
    ZemaitijojePristatyti: 'Žemaitijoje didesnius kiekius galime pristatyti!',
    parasykiteMums: 'Parašykite mums',
    vardasPavarde: 'Jūsų vardas, pavardė:',
    jusuEmail: 'Jūsų el. paštas:',
    jusuTel: 'Jūsų telefono numeris:',
    jusuMiestas: 'Jūsų miestas:',
    zinutesTekstas: 'Tekstas:',
    siusti: 'Siųsti',
    atsiprasomeEmail: 'Apgailestaujame...',
    atsiprasomeMessageEmail: 'Jūsų žinutė nebuvo išsiųsta. Prašome susisiekti el. paštu:',
    thankYou: 'Thank you!',
    messageSent: 'Your message has been sent.',
    // production
    kaipGamintiBio: 'Biohumuso gamyba',
    biohumusGamybaSusideda: 'Biohumuso gamyba susideda iš trijų pagrindinių etapų:',
    pirmasEtapas: 'Pirmas etapas - „vartymas“.',
    ruosiamasSubstratas: 'Ruošiamas substratas - maistas sliekams. Tai aikštelė patalpų viduje, kur pradinės žaliavos paruošiamos iki reikalingos kondicijos ir sumaišomi visi reikalingi komponentai (galvijų mėšlas, lapai, žolė, vaisių bei daržovių išspaudos ir t.t.). Vėliau pagal atitinkamą technologiją substratas dedamas į rietuves, kur ir vyksta kompostavimas. Kompostavimas vyksta iki dviejų mėnesių. Per tą laiką, masė, kuri supilta į rietuvę, permaišoma ne mažiau kaip 8 kartus laikantis technologijos.',
    rietuveseVyksta: 'Rietuvėse vyksta aerobiniai procesai, skaidosi medžiagos ir kompostuojasi. Gaunasi vienalytė medžiaga - kompostas. Rietuvėse vyksta pakankamai sudėtingi cheminiai ir biologiniai procesai, kurių metu temperatūra pakyla iki +70 °C. Tokiu būdu paruoštas kompostas yra ekologiškai švarus ir neturintis kvapo maistas sliekams.',
    antrasEtapas: 'Antras etapas – sliekų šėrimas.',
    taiAtskiraPatalpa: 'Tai atskira patalpa, kur iš komposto yra suformuotos vermi ložės, kuriuose gyvena įveisti sliekai. Sliekai perdirba kompostą, iš kurio gaunamas biohumusas.',
    sliekamsPerdirbant: 'Sliekams perdirbant kompostą į biohumusą reikalingos geros sąlygos. Turi būti sudarytas reikalingas mikroklimatas, neturi būti pašalinių garsų, jais reikia rūpintis kaip ir kitais naminiais gyvūnais. Sliekai labai reaguoja į aplinkos pokyčius. Pasikeitus mikroklimatui, ar atsiradus pakankamai trikdančiai aplinkai, sliekų produktyvumas sumažėja ir jie perdirba mažesnius kiekius komposto.',
    treciasEtapas: 'Trečias etapas - biohumuso atskyrimas ir fasavimas.',
    siameEtapeZaliavinis: 'Šiame etape žaliavinis biohumusas brandinamas, džiovinamas, sijojamas. Po to biohumusas yra pakuojamas į atitinkamą tarą. Biohumuso kokybė labai priklauso būtent nuo šiame etape atliktų darbų.',
    susidomejoteBioGamyba: 'Susidomėjote biohumuso gamyba? Turite daugiau klausimų? Susisiekite su mumis, mes Jums su malonumu padėsime.',
    norintSusisiekti: 'Norint susisiekti ',
    spauskiteCia: 'spauskite čia',
    arba: 'arba',
    telefonoNumeriai: 'Tel.: +370 (659) 13 566 arba +370 (680) 47 200',
    elPastas: 'El. paštas: ',
    elPastasSiusti: 'info@ekovormas.lt',
    // usage
    bioLaukoIrSilt: 'Biohumusas Lauko ir šiltnamio daržovėms',
    didinaAtsparuma: 'Didina atsparumą neigiamiems aplinkos veiksniams',
    gausinaDerliu: 'Gausina derlių ir spartina jo brandą',
    gerinaDerliauMaistines: 'Gerina derliaus maistines ir skonio ypatybes',
    slopinaFitOrganizm: 'Slopina fitopatogeninių organizmų aktyvumą',
    naudojimRekomend: 'Naudojimo rekomendacijos:',
    iterpiamaArbBarstoma: 'įterpiama arba barstoma lokaliai. Biohumusą išberkite ir įterpkite į dirvą prieš sodindami arba vegetacijos pradžioje, vėliau naudokite vegetacijos laikotarpiu (aktyvaus augimo metu; prieš žydėjimą; po žydėjimo; formuojantis derliui). Jei dažnai laistote arba lietinguoju periodu biohumusą naudokite dažniau.',
    naudojimoKiekiai: 'Naudojimo kiekiai:',
    kaiSejamosSeklos: 'kai sėjamos sėklos ir pikuojami daigai',
    kaiPersodinami: 'kai persodinami daigai',
    laukoDarzovems: 'lauko daržovėms (gerinant dirvožemį)',
    bulvems: 'bulvėms',
    sodinimoDuobutei: '200 ml/1 augalui (sodinimo duobutei)',
    siltnamioDarzovems: 'šiltnamio daržovėms',
    daiguiGruntui: '300 ml/1 daigui arba 500 ml/1 l grunto, arba 1 l/m',
    papGerinant: 'papildomai gerinant augalų dirvožemį',
    trysPenkAugalui: '300-500 ml/1 augalui arba 1 l/ m',
    arbaVandens: 'arba 1 l/10 l vandens',
    dirvArbatelei: "dirvožemio 'arbatėlei' gaminti ",
    siltoVandensIrCukrausParuosta: '1 l/10 l šilto vandens ir 50 g cukraus. Paruoštą mikstūrą intensyviai maišant laikyti 2-3 paras, paskui ja laistyti daržoves',
    bioSeklomsDaiginti: 'Biohumusas Sėkloms daiginti ir daigams persodinti',
    skatinaSekluSudyg: 'Skatina sėklų sudygimą',
    spartinaDaiguPrigijima: 'Spartina daigų prigijimą',
    stimuliuojaSaknuAtsi: 'Stimuliuoja šaknų atsiradimą',
    gerinaMitybiniuMedz: 'Gerina mitybinių medžiagų įsisavinimą iš dirvožemio',
    naudojimoRekomend: 'Naudojimo rekomendacijos:',
    iterpiamaArbaBarstoma: 'įterpiama arba barstoma lokaliai. Biohumusą sumaišykite su išsėjimo substratu arba išberkite ir įterpkite į dirvą prieš daigų (svogūnėlių, sodmenų, sodinukų) sodinimą.',
    neutralausPhDurpiu: '300 ml/1 l neutralaus pH durpių arba 200 ml/1 l durpių substrato',
    kaiPersodinamiDaigai: 'kai persodinami daigai (sodmenys, sodinukai)',
    daiguiArbaGrunto: '200 ml/1 daigui arba 400 ml/1 l grunto arba 700 ml/m',
    papildomaiGerinantAugalu: 'papildomai gerinant augalų dirvožemį ',
    augaluArbaVandens: '300-500 ml/1 augalui arba 1 l/ m2 arba 1 l/10 l vandens',
    siltoVandensIrCukraus: '1 l/10 l šilto vandens ir 50 g cukraus',
    bioSodoAugalamsIrVejoms: 'Biohumusas Sodo augalams ir vejoms',
    stiprinaAugImunitet: 'Stiprina augalų imunitetą',
    stimuliuojAugalAugima: 'Stimuliuoja augalų augimą ir vystymąsi',
    spartinaGausinaIrSodrina: 'Spartina, gausina ir sodrina žydėjimą, žiedynų formavimąsi',
    apsaugoNuoChlorozes: 'Apsaugo nuo chlorozės ir kitų fiziologinių ligų',
    naudRekom: 'Naudojimo rekomendacijos:',
    iterpiamaArbaBarstomaLokaliai: 'įterpiama arba barstoma lokaliai. Biohumusą išberkite ir įterpkite į dirvą prieš sodindami arba vegetacijos pradžioje, vėliau naudokite vegetacijos laikotarpiu (aktyvaus augimo metu; prieš žydėjimą; po žydėjimo; formuojantis derliui). Jei dažnai laistote arba lietinguoju periodu biohumusą naudokite dažniau. Aplink krūmus ir medžius barstykite ir įterpkite šakų vainiko pločio ruože.',
    kaiSejamosSeklosIrPikuojami: 'kai sėjamos sėklos ir pikuojami daigai ',
    kaiPersodinamiDaigaiIrViskas: 'kai persodinami daigai ',
    neLaukoDarzovems: 'daržovėms (gerinant dirvožemį)',
    braskemsIrUogenojams: 'braškėms ir uogienojams',
    vienasAugalujSodinDuobut: '300 ml/1 augalui (sodinimo duobutei) arba 2 l/m',
    uogakrumiamsIrVaiskrumiams: 'uogakrūmiams ir vaiskrūmiams ',
    augaluiSodDuobutAstuoniLperM: '2 l/1 augalui (sodinimo duobutei) arba 8 l/m',
    vaismedziams: 'vaismedžiams',
    augalujDuobutArbaDvylikLperM: '4 l/1 augalui (sodinimo duobutei) arba 12 l/m',
    dekoratAugal: 'dekoratyviniams augalams ',
    augaluiSodDuobSesSimtperL: '500 ml/1 augalui (sodinimo duobutei) arba 600 ml/1 l grunto arba 1 l/m',
    vejoms: 'vejoms',
    SiltVandensKurisParuostaMikstura: '1 l/10 l šilto vandens ir 50 ir cukraus. Paruoštą mikstūrą intensyviai maišant laikyti 2-3 paras, paskui ja laistyti augalus',
    // universalMixture
    universalMixtureTitle: 'Universalus mišinys',
    universalP1: 'Universalaus mišinio aprašymas: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu augue neque. Nullam bibendum nisi porta orci venenatis, at viverra ligula ornare. Cras sagittis iaculis sem et interdum. Phasellus consequat commodo magna, vel finibus justo interdum vel. Donec ultricies enim a leo aliquam tristique. Duis bibendum hendrerit ex, sit amet aliquam sapien sagittis nec. Integer eget hendrerit erat. Nunc blandit nunc non est blandit viverra.',
    // californian worms
    wormsP1: 'Kalifornijos raudonieji sliekai (lot. Eisenia fetida) dar vadinami kompostiniai sliekai buvo išveisti 1959 metais Jungtinių Amerikos Valstijų Kalifornijos universitete. Dėl savo unikalių savybių jie greitai išplito po visą pasaulį, prisitaikė prie įvairių klimato sąlygų, o tame tarpe ir mūsų kraštui būdingų skirtingų metų laikų. Lyginant su kitomis rūšimis šie sliekai pasižymi tuo, kad yra žymiai produktyvesni ir ilgaamžiai.',
    wormsP1b: ' Kalifornijos sliekų gyvenimo trukmė 4 kartus ilgesnė nei kitų sliekų ir gali tęstis iki 16 metų. ',
    wormsP1_2: 'Tai reiškia, jog tinkamai juos prižiūrint tai yra pakankamai greitai atsiperkanti ir tuo pačiu ilgalaikė investicija į biohumuso gamybą.',
    wormsH2_1: 'Kalifornijos sliekų fiziologinės savybės ir auginimas',
    wormsP2: 'Suaugę Kalifornijos sliekai yra raudonos spalvos, tačiau išsiritę iš kokono, maždaug 5 - 6 dienas jie būna baltos spalvos, o po 15 - 20 dienų pradeda įgauti rausvą atspalvį. Bręstant sliekai minta mikroorganizmais ir organinėmis sultimis. Kalifornijos sliekai pradeda daugintis maždaug po trijų mėnesių nuo išsiritimo. Šie sliekai yra maždaug 10 kartų vislesni nei kitos rūšys. Kalifornijos sliekai dauginasi kas 7 dienas. Intensyviausias jų veisimosi laikotarpis yra pavasario ir rudens sezonas.',
    wormsP2b: 'Vienas Kalifornijos sliekas per kalendorinius metus gali atsivesti 1500 palikuonių. ',
    wormsP2_2: 'Šių sliekų augimas trunka maždaug septynis mėnesius. Suaugusių sliekų ilgis siekia 6 - 8 centimetrus, jų svoris yra maždaug 0,8 - 1 gramas. Sliekai yra hermafroditai - turi vyriškus ir moteriškus lyties organus.',
    wormsP3: ' Kalifornijos sliekai pasižymi tuo jog yra pakankamai ėdrūs.',
    wormsP3b: 'Palaikant jų veiklai tinkamą 15 - 25 laipsnių temperatūrą jie per parą gali suėsti dvigubai daugiau maisto nei sveria patys. ',
    wormsP3_2: 'Sliekų auginimas nereikalauja daug priežiūros, tačiau labai svarbu, jog jie visada turėtų pakankamai maisto ir niekada nebūtų alkani. Sliekų mitybai tinkamos įvairios organinės medžiagos ir atliekos - mėšlas, pjuvenos, drožlės, lapai, perpuvęs šienas, žolė. Taip pat galima įterpti ir celiuliozės gaminių: tualetinio popieriaus, servetėlių, rankšluosčių. Galima naudoti ir atliekas nuo stalo, tačiau svarbu vengti medžiagų, kurios gali sukelti dirvožemio rūgštingumą, pavyzdžiui - pieno, mėsos, riebalų ar sūraus maisto liekanų. Siekiant, kad sliekų auginimo terpė nepasižymėtų rūgštingumu jiems būtini smulkinti kiaušinių lukštai.',
    wormsP4: 'Norint, kad sliekai nepradėtų nykti dirvožemio drėgmės lygis turi būti palaikomas ir siekti 80 procentų.',
    wormsP4_2: 'Vasaros sausrų metu dirvožemį kuriame auginami Kalifornijos sliekai būtina laistyti, taip pat dera vengti sausų atliekų. Sliekai bijo šviesos, tad turi būti laikomi tamsoje. Sliekai pasižymi šalčio baime, todėl atėjus žiemai reikia pasirūpinti, kad jie nesušaltų - palaikyti tinkamą temperatūrą, apšildyti dėžes, kuriuose jie laikomi. Šaltuoju metu laikotarpiu jie taip pat išlieka produktyvūs ir gamina biohumusą. Priklausomai nuo reikalingo humuso kiekio Kalifornijos raudonieji sliekai yra auginami didesnėse ar mažesnėse talpyklose, pavyzdžiui - medinėse dėžėse. Puvimo procesui būtinas deguonis, tad siekiant palaikyti oro cirkuliaciją dėžių šonuose turi būti išgręžtos skylės.',
    wormsP5: 'Ir nors iš pirmo žvilgsnio visi sliekai atrodo vienodi ir tuo pačiu labai paprasti bei primityvūs sutvėrimai, tačiau būtent jie atsakingi už dirvožemio išvalymą, efektyvų organinių atliekų suskaidymą. Na, o efektyvumo ir aktyvumo skalėje pirmauja būtent Kalifornijos sliekai. Jų organizmas pasisavina maždaug 40 procentų ėdalo, o likusius 60 procentų perdirba ir pašalina.',
    wormsP5b: ' Kalifornijos sliekams nereikalingos atliekos, tai nedideli smulkūs grumsteliai - biohumusas, tai yra stabilizuotos, ekologiškos o svarbiausia, kad efektyvios organinės trąšos.',
    // navigation
    sliekai: 'Sliekai',
    universalusMisinys: 'Universalus mišinys',
    kaipNaudotiBiohumusa: 'Biohumuso naudojimas',
    kalifornijosSliekai: 'Kalifornijos sliekai, naktiniai sliekai žvejybai',
    apieMus: 'Apie mus',
    kontaktai: 'Kontaktai',
    gallery: 'Nuotraukos',
    // footer
    visosTeisesSaugomos: 'Visos teisės saugomos.',
    // error page
    errorPageText: 'Atsiprašome. Tokio puslapio nėra...',
    // meta
    homeTitle: 'Parduodamas biohumusas, kalifornijos sliekai | EkoVormas',
    homeDescription: 'Parduodamas biohumusas, kompostas, sliekai žvejybai. Mažmeninė ir didmeninė prekyba. Bekvapės trąšos skirtos lauko ir šiltnamio augalams tręšti',
    usageTitle: 'Biohumuso naudojimas|Kur ir kaip naudoti biohumusą?|EkoVormas',
    usageDescription: 'Biohumusas neturi nemalonaus kvapo, todel jį galima naudoti lauko ir šiltnamio augalams. Kaip naudoti biohumusą galite paskaityti mūsų puslapyje.',
    productionTitle: 'Biohumuso gaminimo procesas. Kaip gaminti biohumusą?|EkoVormas',
    productionDescription: "Biohumuso gaminimo procesas susideda iš trijų pagrindinių etapų: pirmas etapas - 'vartymas', antras etapas – 'sliekų šėrimas', trečias etapas - 'biohumuso atskyrimas ir fasavimas'.",
    buyVermicompostTitle: 'Parduodamas biohumusas | EkoVormas',
    buyVermicompostDescription: 'Parduodamas biohumusas, sliekai. Biohumusas 10, 30 ir 1000 litrų pakuotėje.',
    aboutTitle: 'Apie mus | EkoVormas',
    documentsTitle: 'Aktuali informacija | EkoVormas',
    uploadDocumentsTitle: 'Dokumentų įkėlimas | EkoVormas',
    contactTitle: 'Kontaktai | EkoVormas',
    errorPageTitle: 'Puslapis nerastas | EkoVormas',
    wormsTitle: 'Kalifornijos sliekai | EkoVormas',
    californianWormsDescription: 'Kalifornijos sliekų fiziologinės savybės ir auginimas. Kalifornijos sliekų šėrimas norint išgauti biohumusą. Kuo minta sliekai ir geriausios kalifornijos sliekų auginimo salygos.',

  },


  // ENGLISH
  en: {
    // home
    KasYraBiohumusas: 'What is vermicompost?',
    Biohumusas: 'Vermicompost',
    biohumusasTaiIsOrganiniu: 'Vermicompost (also known as biohumus) is produced from organic matter (manure, organic waste) by Californian worms. It’s a natural, microbial organic fertilizer, without any chemicals.',
    biohumusasYraKertuEfektyvesnis: 'Vermicompost is 10 - 15 times more efficient than any other organic fertilizer. It eliminates all parasites residing in the soil for up to 6 months.',
    biohumusasPraturtinaDirvosStruktura: 'Biohumus enriches the soil structure and revitalizes soil microbial activity. It also increases plant resistance to adverse environmental factors and diseases. It’s a natural growth stimulator, which facilitates plant rooting, engraftment, growth. Biohumus maintains moisture balance and improves the quality of the harvest.',
    biohumusasNeturiNemalonausKvapo: 'Vermicompost does NOT have an unpleasant smell, which is really important to home growers or farms near residential territory. Biohumus reduces the nitrate concentration levels in the soil and helps healing degraded soil. It’s scientifically proven, that Vermicomposts contains up to 100 times more beneficial bacteria, which restore soil fertility, compared to manure. Our product is perfectly suitable for a variety of vegetables. With biohumus you can grow 100% Bio products! Using Vermicomposts You can expect from 15% to 50% higher harvest, depending on the plant species. ',
    biohumusasNaudojamasZemesUkio: 'Vermicompost is used for planting and fertilizing crops, reanimating and regenerating the soil.',
    bioAtvirameGrunteGalima: 'Vermicomposts on open ground can be used from early spring until autumn. His effectiveness ',
    issilaikoNetKetSep: 'lasts up to 4 - 7 years!',
    nereikesNiekoKito: 'By using vermicompost, there is no need to use any other chemical or organic fertilisers.',
    skaitytiDaugiau: 'Read more...',
    bioNaudojimasGarantuoja: 'Vermicompost guarantees:',
    greitesniSekluPrigijima: 'Faster seed germination and more rapid seedling regeneration',
    geresneDirvosStrukt: "Better soil's and roots structure",
    ankstesniDerliausSubrendima: "Earlier crop growth and longer it's the maintenance",
    augaluAtsparumaTemp: 'Plant resistance to temperature changes and diseases',
    gausesniIrSveikesni: 'Bigger and healthier harvest',
    stipresniAugaluImuniteta: 'Stronger plants immunity and protection from chlorosis and other physiological diseases',
    dirvosDerlingumoAtstatyma: 'Soil productivity restoration (even mixed with sand biohumus consists of fertile soil)',
    dirvozemioMikroAktyvumas: 'Soil microbiological activity',
    // our production (for sale)
    musuProdukcija: 'Other products for sale',
    gaminameBiohumusoMis: 'Vrmicompost mixtures',
    gaminameKomposta: 'Compost',
    galimeFasuotaIrNe: 'Pre-packaged or non-prepacked vermicompost',
    parduodameSliekus: 'California worms',
    mesParduodam: 'Vermicompost, universal mixture for sale',
    liters: 'Liters',
    uzsisakyti: 'Order now',
    kuboKaina: 'Contract',
    buyVermicompost: 'vermicompost',
    buyUniversalMixture: 'universal mixture',
    // about
    kasMesEsam: 'Who we are?',
    ekovormasBuvoIkurtas: 'Ekovormas was founded in 2016 in Lithuania. The main activity of our company - to produce vermicompost and compost, grow Californian worms.',
    musuMisija: 'Our mission',
    musuMisijaYra: 'Our mission is to promote organic farming production and distribution of natural organic biologically active plant growth stimulating measures and providing a reliable, research-based information about positive impact of organic farming, environment and human health; the use of innovative practice, science-based organic farming methods.',
    musuVizija: 'Our vision',
    taptiPagrEfektyv: 'Become the main effective, natural organic bioactive plant development and stimulating environment and human health friendly materials supplier in the market. ',
    isvystytiEfektyviusMosklu: 'Promote effective, science-based organic farming methods and ensure smooth their installation in practice.',
    taptiIssamiuPatikimos: 'Become a detailed and reliable source of information about organic farming.',
    musutikslas: 'Our goal',
    musutikslasYra: 'Our goal is to promote organic farming manufacturing and carry of natural organic biologically active plant grow stimulating measures and to provide information on the positive impact of organic farming on the environment and human health.',
    // Documentai
    ikeltiDokumentus: 'Upload Documents',
    Dokumentai: 'Documents',
    wrongPass: 'Wrong password.',
    uploadSorry: 'Error ',
    uploadError: 'error ',
    fillFields: 'Please enter password and select a file.',
    typeErr: 'This file type is not allowed',
    tooLarge: 'File to large.',
    uploacSuccess: 'file uploaded',
    uploadSuccessGreet: 'Success ',
    fileExists: 'There is already file with the same name.',
    noFilesFound: 'No documents found',
    docSubmit: 'Upload',
    // kontaktai
    CentralLithuania: 'Central Lithuania',
    TikBiohumusas: 'only vermicompost',
    Zemaitija: 'Western Lithuania',
    BiohumusasIrSliekai: 'vermicompost and worms',
    ZemaitijojePristatyti: 'We are able to deliver larger quantities in Western Lithuania!',
    parasykiteMums: 'Contact us',
    vardasPavarde: 'Name and surname:',
    jusuEmail: 'E-mail: ',
    jusuTel: 'Phone number:',
    jusuMiestas: 'City:',
    zinutesTekstas: 'Message:',
    siusti: 'Send',
    atsiprasomeEmail: 'We are sorry!',
    atsiprasomeMessageEmail: 'Your message has not been sent. Please contact us via email:',
    thankYou: 'Thank you!',
    messageSent: 'Your message has been sent.',
    // production
    kaipGamintiBio: 'Production of vermicompost',
    biohumusGamybaSusideda: 'Vermicompost is made by three main steps:',
    pirmasEtapas: "First step – 'flipping'",
    ruosiamasSubstratas: 'Preparing substrate – food for California worms. It is a lot inside the building where material is prepared for necessary condition and mixed all the necessary components (cattle manure, leaves, grass, fruit and vegetables, cake, etc.). Later, according to the relevant technology, the substrate is placed in the stacks, where is making composting. Composting takes up to two months. During that time, the mass, which was taken up in a stack is mixed at least 8 times in accordance with the technology.',
    rietuveseVyksta: 'Stacks takes place anaerobicis expanded material and composting. Comes out a homogeneous material - compost. Stacks going relatively complex chemical and biological processes in which the temperature rises to +70 ° C. In this way, ready compost is environmentally clean and odor-free food for California worms.',
    antrasEtapas: "Second step – 'feeding californian worms'",
    taiAtskiraPatalpa: 'This is a separate room where Californian worms make vermicompost. Worms transform compost into vermicompost.',
    sliekamsPerdirbant: 'During the processing of the vermicompost, a certain set of conditions must be met in order to get positive results from the worms’ work. Specific microclimate must be maintained and external noises must be silenced. They need to be taken care of as any other pets. They are sensitive to all kinds of environmental changes. Such changes in the microclimate, could reduce their productivity and thus lead not only to lower amounts of compost but also lower quality.',
    treciasEtapas: "Third step – 'vermicompost separation and packaging'",
    siameEtapeZaliavinis: 'At this stage the raw vermiconpost aged, dried and sieved. After that vermicompost is packed in appropriate containers. Vermicompost quality depends precisely on this phase of the work performed.',
    susidomejoteBioGamyba: 'Interested in vermicompost production? Have more questions? Contact us, we will be happy to help you.',
    norintSusisiekti: 'To contact ',
    spauskiteCia: 'click here ',
    arba: 'or',
    telefonoNumeriai: 'Phone: +370 (659) 13 566',
    elPastas: 'E-mail: ',
    elPastasSiusti: 'info@ekovormas.com',
    // usage
    bioLaukoIrSilt: 'Vermicompost for field and greenhouse vegetables',
    didinaAtsparuma: 'Increases resistance to negative environmental factors',
    gausinaDerliu: 'Boosts harvest and increase its maturity',
    gerinaDerliauMaistines: 'Improves Harvest nutrients and taste',
    slopinaFitOrganizm: 'Suppresses activity of the phytopathogenic organisms',
    naudojimRekomend: 'Recommendations for use:',
    iterpiamaArbBarstoma: 'Insert or spread locally. Pour biohumus and insert into the soil before planting or at the beginning of vegetation, then use during the vegetation period (during the growing season, before flowering, after flowering yield formation). If frequently watering or during the rainy period, use biohumus more frequently.',
    naudojimoKiekiai: 'Rate of use:',
    kaiSejamosSeklos: 'when sowing seeds and pricking out seedlings',
    kaiPersodinami: 'when replanted seedlings',
    laukoDarzovems: 'field vegetables (improving soil)',
    bulvems: 'potatoes',
    sodinimoDuobutei: '200 ml/1 plant',
    siltnamioDarzovems: 'greenhouse vegetables',
    daiguiGruntui: '300 ml/1 seedling or 500 ml/1 l soil, or 1 l/m',
    papGerinant: 'additional plant soil improvement	',
    trysPenkAugalui: '300-500 ml/1 plant or 1 l/ m',
    arbaVandens: 'or 1 l/10 l water',
    dirvArbatelei: "to make 'soil tea' ",
    siltoVandensIrCukrausParuosta: '1 l/10 l warm water and 50 g sugar. After preparing the mixture with intensive mixing, hold for 2-3 days, then water the vegetables',
    bioSeklomsDaiginti: 'Vermicomposts for seeds grow and seedling transplanting',
    skatinaSekluSudyg: 'Promotes seed germination',
    spartinaDaiguPrigijima: 'Advances seedling engraftment',
    stimuliuojaSaknuAtsi: 'Stimulates root coming',
    gerinaMitybiniuMedz: 'Improves nutrient uptake from the soil',
    naudojimoRekomend: 'Recommendations for use:',
    iterpiamaArbaBarstoma: 'Insert or spread locally. Vermicomposts mix with seeding substrate, or Pour and insert into the soil before seedling (onion, seedlings, saplings) planting.',
    neutralausPhDurpiu: '300 ml/1 l neutral pH peat or 200 ml/1 l peat substrate',
    kaiPersodinamiDaigai: 'when replanted seedlings (seedlings, saplings)',
    daiguiArbaGrunto: '200 ml/1 seedling or 400 ml/1 l soil or 700 ml/m',
    papildomaiGerinantAugalu: 'additional plant soil improvement',
    augaluArbaVandens: '300-500 ml/1 plant or 1 l/ m2 or 1 l/10 l water',
    siltoVandensIrCukraus: '1 l/10 l warm water and 50 g sugar',
    bioSodoAugalamsIrVejoms: 'Biohumus for garden plants and lawns',
    stiprinaAugImunitet: 'Increases immunity of plants',
    stimuliuojAugalAugima: 'Stimulates plant growth and development',
    spartinaGausinaIrSodrina: 'Increase and enrich flowering, inflorescence formation',
    apsaugoNuoChlorozes: 'Prevents chlorosis and other physiological diseases',
    naudRekom: 'Recommendations for use:',
    iterpiamaArbaBarstomaLokaliai: 'Insert or spread locally. Pour biohumus and insert into the soil before planting or at the beginning of vegetation, then use during the vegetation period (during the growing season, before flowering, after flowering yield formation). If frequently watering or during the rainy period, use biohumus more frequently. Around the bushes and trees spread and insert branches corona width section.',
    kaiSejamosSeklosIrPikuojami: 'when sowing seeds and pricking out seedlings',
    kaiPersodinamiDaigaiIrViskas: 'when replanted seedlings	',
    neLaukoDarzovems: 'vegetables (improving soil)',
    braskemsIrUogenojams: 'strawberries',
    vienasAugalujSodinDuobut: '300 ml/1 plant or 2 l/m',
    uogakrumiamsIrVaiskrumiams: 'fruit bushes',
    augaluiSodDuobutAstuoniLperM: '2 l/1 plant or 8 l/m',
    vaismedziams: 'vaismedžiams',
    augalujDuobutArbaDvylikLperM: '4 l/1 plant or 12 l/m',
    dekoratAugal: 'decorative plants ',
    augaluiSodDuobSesSimtperL: '500 ml/1 plant or 600 ml/1 l soil or 1 l/m',
    vejoms: 'lawns',
    SiltVandensKurisParuostaMikstura: '1 l/10 l warm water and 50 g sugar. After preparing the mixture with intensive mixing, hold for 2-3 days, then water the plants',
    // universalMixture
    universalMixtureTitle: 'Universal mixture',
    universalP1: 'Universal mixture description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu augue neque. Nullam bibendum nisi porta orci venenatis, at viverra ligula ornare. Cras sagittis iaculis sem et interdum. Phasellus consequat commodo magna, vel finibus justo interdum vel. Donec ultricies enim a leo aliquam tristique. Duis bibendum hendrerit ex, sit amet aliquam sapien sagittis nec. Integer eget hendrerit erat. Nunc blandit nunc non est blandit viverra.',
    // californian worms
    wormsP1: 'Red californian worms (Eisenia fetida) are also known as composting worms were introduced in 1959, University of California, USA. Due to its unique characteristics they instantly spread around the globe and adjusted to various climates. Compared with other worm species, Californian worms is characterized by being more productive and durable.',
    wormsP1b: ' Red californian worms lifetime is 4 times longer than any other worms, therefore they can survive up to 16 years.',
    wormsP1_2: 'Based on these characteristics, if the worms are grown under applicable conditions, they can produce vermicompost. Production of the vermicompost is a quick return investment.',
    // wormsH2_1: "Kalifornijos sliekų fiziologinės savybės ir auginimas",
    wormsH2_1: 'Physiological properties of California earthworms',
    wormsP2: 'Adult California worms are red in color, but appearing white from the cocoon for about 5 to 6 days, and after 15-20 days begin to get pink. California worms begin to breed about three months after hatching. These earthworms are about 10 times as large as other worm species. California earthworms multiply every 7 days. The most intensive breeding period is spring and autumn seasons.',
    wormsP2b: 'One California earthworm can produce 1500 worms per calendar year.',
    wormsP2_2: 'Earthworm growth takes around seven months. The length of the adult earthworm ranges from 6 to 8 centimeters and weigh from 0.8 to 1 gram. Earthworms are hermaphrodites - have male and female genitals.',
    wormsP3: " California's earthworms are characterized by consuming a lot of food.",
    wormsP3b: ' By keeping them at a temperature of 15-25 degrees, they can eat twice amount of food their weight. ',
    wormsP3_2: 'Growing californian worm does not require much care, but it is important that they always have enough food and are never hungry. Various organic materials and wastes are suitable for earthworms - manure, sawdust, shavings, leaves, hay, grass. It is also possible to insert pulp products, such as toilet paper, napkins, towels. It is also possible to use waste from the table, but it is important to avoid materials that can cause acidity in the soil, such as residues of milk, meat, fat or salt.',
    wormsP4: 'In order to make the earthworms multiply, the soil moisture level must be maintained at 80 percent.',
    wormsP4_2: "During the summer drought, the soil in which the California worms need to be watered is also good, and it is also good to avoid dry waste. Earthworms are afraid of light, so they must be kept in the dark. Earthworms have a fear of frost, so when winter comes, care must be taken that they do not freeze - to maintain the right temperature, to heat the boxes in which they are stored. During the cold season they also remain productive and produce vermicompost (biohumus). Depending on the amount of humus required, California's red earthworms are grown in larger or smaller containers such as wooden boxes. Oxygen is required for the rotting process, so holes must be drilled in the sides of the boxes to maintain air circulation.",
    wormsP5: 'And, at first glance, all earthworms look the same and at the same time very simple and primitive, but they are responsible for soil cleansing, an effective breakdown of organic waste. Well, California\'s earthworms are at the forefront of efficiency and activity. Their body absorbs about 40 percent of the food, and the remaining 60 percent recovers and removes.',
    wormsP5b: ' California earthworms do not require waste, they are small fine grits - vericompost, that is stabilized, ecological, and most importantly, effective organic fertilizers.',

    // navigation
    sliekai: 'California worms',
    universalusMisinys: 'Universal mixture',
    kaipNaudotiBiohumusa: 'Usage of vermicompost',
    kalifornijosSliekai: 'Californian worms, nocturnal earthworms for fishing',
    apieMus: 'About us',
    kontaktai: 'Contacts',
    gallery: 'Gallery',
    // footer
    visosTeisesSaugomos: 'All rights reserved',
    // error page
    errorPageText: 'We are sorry, but the page You are looking was not found...',
    // meta
    homeTitle: 'Vermicompost for sale | 100% organic fertilizer | EkoVormas',
    homeDescription: 'Vermicompost for sale. Fully organic fertilizer without any unpleasant smell. Everything you need to know about the vermicompost: how to produce vermicompost? What is vermicompost? How to use vermicompost? Where to buy vermicompost?',
    usageTitle: 'How, when and where to use vermicompost?|EkoVormas',
    usageDescription: 'Description of how to use vermicompost. Quantities and step by step instruction and use rates for vermicompost usage.',
    productionTitle: 'How to produce vermicompost?|EkoVormas',
    productionDescription: "Production of vermicompost consists of three main steps: 'flipping', 'feeding the worms' and 'vermicompost separation and packaging'. ",
    buyVermicompostTitle: 'Vermicompost for sale | EkoVormas',
    buyVermicompostDescription: 'Vermicompost, californian worms for sale. Buy big quantities of vermicompost from Europe.',
    aboutTitle: 'About us | EkoVormas',
    documentsTitle: 'Documents | EkoVormas',
    uploadDocumentsTitle: 'Upload Documents | EkoVormas',
    contactTitle: 'Contact us | EkoVormas',
    errorPageTitle: 'Page not found | EkoVormas',
    wormsTitle: 'Californian worms | EkoVormas',
    californianWormsDescription: 'An article about red californian earthworms (Eisenia fetida). How to grow red californian worm, how and what to feed them and how californian worm produces vermicompost.',


  },
};
