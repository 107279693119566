import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Sliekas from '../img/sliekasLogo.svg';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {setLanguage} from '../actions/NavbarActions';

const changeLanguage = (history, oldLanguage, language, changeFunc) => {
    const {pathname} = history.location;
    const newPathname = pathname.replace('/' + oldLanguage + '/', '/' + language + '/');
    changeFunc(language);
    history.push(newPathname);
};

function _closeMenu(e) {
  const menu = e.currentTarget;
    menu.classList.remove('in');
}

function mapStateToProps(state) {
    return {
        language: state.i18n.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({setLanguage}, dispatch),
    };
}

const Navbar = (props) => {
    const { setLanguage } = props.actions;
    const { language } = props;
    return (
        <React.Fragment>
            <div className="jumbotron jumbotron-fluid">
                <div className="row">
                    <div className="col-md-2 text-center">
                        <img className="logo" src={Sliekas} alt="Ekovormas logotipas"/>
                    </div>
                    <div className="col-md-8 text-center">
                        <h1 className="display-1"><span className="zalia_pav">
                            <b>Eko</b></span><span className="tamsi_spalva_pav">
                            <b>Vormas</b></span>
                        </h1>
                        <p className="display-4"><span className="mazas_pavadinimas">
                            <b className="production">
                                <Translate className="production-item" value='Biohumusas'/>
                                &#9679;
                                <Translate className="production-item" value='sliekai'/>
                            </b>
                        </span>
                        </p>
                    </div>
                    <div className="topRightCorner">
                        <a onClick={() => changeLanguage(props.history, props.language, 'en', setLanguage)}
                           className={`lang-link tamsi_spalva_pav ${language === 'en' ? 'lang-current' : ''}`}>
                            <b className="lang-link-text">EN </b>
                        </a>
                        <a onClick={() => changeLanguage(props.history, props.language, 'lt', setLanguage)}
                           className={`lang-link tamsi_spalva_pav ${language === 'lt' ? 'lang-current' : ''}`}>
                            <b className="lang-link-text">LT </b>
                        </a>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Meniu</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" onClick={(e) => _closeMenu(e)}>
                        <ul className="nav navbar-nav" id="menuList">
                            <li>
                                <Link to={`/${language}/`} className="nav-link">
                                    <Translate value='Biohumusas'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/usage`} className="nav-link">
                                    <Translate value='kaipNaudotiBiohumusa'/>
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to={`/${language}/universal-mixture`} className="nav-link">*/}
                            {/*        <Translate value='universalusMisinys'/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link to={`/${language}/californian-worms`} className="nav-link">
                                    <Translate value='kalifornijosSliekai'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/production`} className="nav-link">
                                    <Translate value='kaipGamintiBio'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/vermicompost-for-sale`} className="nav-link">
                                    <Translate value='mesParduodam'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/about-ekovormas`} className="nav-link">
                                    <Translate value='apieMus'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/documents`} className="nav-link">
                                    <Translate value='Dokumentai'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/images`} className="nav-link">
                                    <Translate value='gallery'/>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${language}/contact`} className="nav-link">
                                    <Translate value='kontaktai'/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </React.Fragment>
    )
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
