import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from "react-helmet";

class CalifornianWorms extends React.Component {

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('wormsTitle')}</title>
                    <meta name="description" content={I18n.t('californianWormsDescription')} />
                </Helmet>
                <div className="container fullScreen">
                    <div className="col-md-6">
                        <h1 className="raide"><Translate value='kalifornijosSliekai'/></h1>
                        <p className="text-justify">
                            <Translate value='wormsP1'/>
                            <b> <Translate value='wormsP1b'/> </b>
                            <Translate value='wormsP1_2'/>
                        </p>
                        <h2 className="raide"><Translate value='wormsH2_1'/></h2>
                        <p className="text-justify">
                            <Translate value='wormsP2'/>
                            <b> <Translate value='wormsP2b'/> </b>
                            <Translate value='wormsP2_2'/>
                        </p>

                        <p className="text-justify">
                            <Translate value='wormsP3'/>
                            <b> <Translate value='wormsP3b'/> </b>
                            <Translate value='wormsP3_2'/>
                        </p>

                        <p className="text-justify">
                            <b><Translate value='wormsP4'/> </b>
                            <Translate value='wormsP4_2'/>
                        </p>

                        <p className="text-justify">
                            <Translate value='wormsP5'/>
                            <b> <Translate value='wormsP5b'/> </b>
                        </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CalifornianWorms;
