import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from "react-helmet";

class About extends React.Component {

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('aboutTitle')}</title>
                </Helmet>
                <div className="container fullScreen">
                <div className="col-md-6">
                    <h1 className="raide"><Translate value='kasMesEsam'/></h1>
                    <p><Translate value='ekovormasBuvoIkurtas'/></p>
                    <h2 className="raide"><Translate value='musuMisija'/></h2>
                    <p className="text-justify"><Translate value='musuMisijaYra'/></p>
                    <h2 className="raide"><Translate value='musuVizija'/></h2>
                    <div className="garantuoja">
                        <ul>
                            <li><Translate value='taptiPagrEfektyv'/></li>
                            <li><Translate value='isvystytiEfektyviusMosklu'/></li>
                            <li><Translate value='taptiIssamiuPatikimos'/></li>
                        </ul>
                    </div>
                    <h2 className="raide"><Translate value='musutikslas'/></h2>
                    <p className="text-justify"><Translate value='musutikslasYra'/></p>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default About;
