import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from "react-helmet";

class Production extends React.Component {

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('productionTitle')}</title>
                    <meta name="description" content={I18n.t('productionDescription')} />
                </Helmet>
                <div className="container fullScreen">
                <div className="col-md-6">
                    <h1 className="raide"><Translate value='kaipGamintiBio' /></h1>
                    <p><Translate value='biohumusGamybaSusideda' /></p>

                    <h3 className="raide"><Translate value='pirmasEtapas' /></h3>
                    <p className="text-justify"><Translate value='ruosiamasSubstratas' /></p>

                    <p><Translate value='rietuveseVyksta' /></p>

                    <h3 className="raide"><Translate value='antrasEtapas' /></h3>
                    <p className="text-justify"><Translate value='taiAtskiraPatalpa' /></p>
                    <p className="text-justify"><Translate value='sliekamsPerdirbant' /></p>

                    <h3 className="raide"><Translate value='treciasEtapas' /></h3>
                    <p className="text-justify"><Translate value='siameEtapeZaliavinis' /></p>

                    <h4 className="raide"><b><Translate value='susidomejoteBioGamyba' /></b></h4>
                    <p><Translate value='elPastas' /><a href="mailto:info@ekovormas.lt" target="_top"><Translate value='elPastasSiusti' /></a></p>

                </div>
        </div>
            </React.Fragment>
        )
    }
}

export default Production
