import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from 'react-helmet';
import axios from 'axios';

class ShowDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            error: false,
        }
        this.handleFileResponse = this.handleFileResponse.bind(this);
        this.handleError = this.handleError.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
    }

    handleFileResponse(data) {
        this.setState({
            data,
            error: false})
    }

    handleError(error) {
        this.setState({
            data: {},
            error
        })
    }

    componentDidMount() {
        this.getDocuments();
    }

    getDocuments() {
        axios.post(`${process.env.REACT_APP_LOCAL_PATH_TO_SCRIPT}listFiles.php`)
        .then((res) => {
            this.handleFileResponse(res.data)
        })
        .catch((error) => {
            this.handleError(error)
        });
    }

    render() {
        const {lang} = this.props.match.params;
        const {data, error} = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('documentsTitle')}</title>
                </Helmet>
                <div className="container fullScreen documents">
                    <div className="col-md-6">
                        <h1 className="raide"><Translate value='Dokumentai'/></h1>
                        <ul className="list-group overflow-control">
                            {data.length > 0 && data.map(file => {
                                return  <a  href={`${process.env.REACT_APP_LOCAL_PATH_TO_UPLOADS}${file}`} 
                                            key={file} 
                                            target='_blank' 
                                            className="list-group-item">{file}
                                        </a>
                            })}
                        </ul>
                        {error &&   <div className="alert alert-warning">
                                        <strong><Translate value="noFilesFound"/></strong> 
                                    </div>    
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ShowDocuments;
