import React, {Component} from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {Helmet} from "react-helmet";

class Home extends Component{

    constructor(props) {
        super(props);
        this.state = {
            showReadMore: false,
        };

      this._showReadMore = this._showReadMore.bind(this);
      }

    _showReadMore() {
        this.setState({
            showReadMore: true
        });
        document.getElementById("readMoreLink").remove();
    }

    render () {
        const { showReadMore } = this.state;
        const hiddenClass = showReadMore ? '' : 'hidden';
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
            <Helmet>
                <html lang={lang} />
                <title>{I18n.t('homeTitle')}</title>
                <meta name="description" content={I18n.t('homeDescription')} />
            </Helmet>
            <div className="container fullScreen">
                <div className="col-md-6">
                    <h1 className="raide"><Translate value='KasYraBiohumusas' /></h1>
                    <p className="text-justify"><Translate value='biohumusasTaiIsOrganiniu' /></p>
                    <p className="text-justify"><Translate value='biohumusasYraKertuEfektyvesnis' /></p>
                    <span className={hiddenClass} id="readMore">
                        <p className="text-justify"><Translate value='biohumusasPraturtinaDirvosStruktura' /></p>
                        <p className="text-justify"><Translate value='biohumusasNeturiNemalonausKvapo' /></p>
                        <p className="text-justify"><Translate value='biohumusasNaudojamasZemesUkio' /></p>
                        <p className="text-justify"><Translate value='bioAtvirameGrunteGalima' /><b><Translate value='issilaikoNetKetSep' /></b></p>
                        <p><b><Translate value='nereikesNiekoKito' /></b></p>
                    </span>
                    <p className="raide link-pointer" id="readMoreLink" onClick={this._showReadMore} ><b><Translate value='skaitytiDaugiau' /></b></p>
                    <h2 className="raide"><Translate value='bioNaudojimasGarantuoja' /></h2>
                    <div className="garantuoja">
                        <ul>
                        <li><Translate value='greitesniSekluPrigijima' /></li>
                        <li><Translate value='geresneDirvosStrukt' /></li>
                        <li><Translate value='ankstesniDerliausSubrendima' /></li>
                        <li><Translate value='augaluAtsparumaTemp' /></li>
                        <li><Translate value='gausesniIrSveikesni' /></li>
                        <li><Translate value='stipresniAugaluImuniteta' /></li>
                        <li><Translate value='dirvosDerlingumoAtstatyma' /></li>
                        <li><Translate value='dirvozemioMikroAktyvumas' /></li>
                        </ul>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}


export default Home;
