import {Helmet} from "react-helmet";
import {I18n, Translate} from "react-redux-i18n";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import axios from "axios";

class ShowImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: null,
            error: ''
        }
    }

    componentDidMount() {
        this.getImages();
    }

    getImages() {
        axios.get(`${process.env.REACT_APP_LOCAL_PATH_TO_SCRIPT}getImages.php`)
            .then((res) => {
                this.handleImagesResponse(res.data)
            })
            .catch((error) => {
                this.handleError(error.message)
            });
    }

    handleImagesResponse(images) {
        this.setState({
            images,
            error: '',
        })
    }

    handleError(error) {
        this.setState({
            images: null,
            error
        })
    }

    render() {
        const { lang } = this.props.match.params;
        const { images, error } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('gallery')}</title>
                </Helmet>
                <div className="container fullScreen documents">
                    <div className="col-md-12">
                        <h1 className="raide"><Translate value='gallery'/></h1>
                        {error && <div className="alert alert-danger">
                            <strong>{error}</strong>
                        </div>}
                        <Carousel>
                            {images && images.map(img => (
                                <div key={img} className="carousel-viewport">
                                    <img src={`${process.env.REACT_APP_LOCAL_PATH_TO_UPLOADS}/images/${img.file_name}`}
                                         alt={img.description}
                                         className="carousel-thumb"/>
                                    <p className="legend zalia_bg">{lang === 'lt' ? img.lt_description : img.en_description}</p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ShowImages;
