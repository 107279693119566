import {Translate} from "react-redux-i18n";
import React from "react";
import axios from "axios";

class UploadImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: '',
            error: '',
        }

        this.uploadInputRef = React.createRef();
        this.handleError = this.handleError.bind(this);
        this.handleUploadResponse = this.handleUploadResponse.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { password, file, en_description, lt_description } = this.state;
        const formData = new FormData();
        formData.append('password', password);
        formData.append('file', file);
        formData.append('lt_description', lt_description);
        formData.append('en_description', en_description);

        axios({
            method: 'post',
            url: process.env.REACT_APP_LOCAL_PATH_TO_SCRIPT + 'uploadImage.php',
            headers: { 'Accept': 'application/json',
            }, data: formData})
            .then(() => {
                this.handleUploadResponse()
            }).catch((err) => {
            if (err.response) {
                const { status } = err.response;
                if (status === 403) {
                    this.handleError("Netinkamas slaptažodis");
                    return;
                }
                if (status === 400) {
                    this.handleError("Netinkama užklausa!");
                    return;
                }
                if (status === 413) {
                    this.handleError("Nuotraukos dydis per didelis!");
                    return;
                }
                if (status === 404) {
                    this.handleError("Nerasta įkėlimo logika!");
                }
            } else {
                this.handleError(err.message);
            }
            });
    };

    handleUploadResponse() {
        this.setState({
            error: '',
            success: 'Failas įkeltas sėkmingai.',
        })
        this.uploadInputRef.current.value = null;
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        if (name === 'file'){
            this.setState({
                file: target.files[0]
            });
        } else {
            this.setState({
                [name]: target.value
            });
        }
    };


    handleError(error) {
        this.setState({
            success: '',
            error,
        })
    }

    render() {

        const { error, success } = this.state
        return (
            <div className="col-md-6">
                <h1 className="raide">Nuotraukų įkėlimas</h1>
                <div className="alert alert-info">
                    Maksimalus nuotraukos dydis <b>300KB!</b> Keliant didesnius failus, puslapio krovimo
                    greitis sulėtės. Pabandykite suspausti nuotrauką neprarandant jos kokybes pasinaudoję
                    nemokamu įrankiu: <a href="https://compressor.io/compress" target="_blank">www.compressor.io</a>
                </div>
                {error &&
                <div className="alert alert-danger">
                    <strong><Translate value='uploadSorry'/></strong><Translate value={error}/>
                </div>}
                {success &&
                <div className="alert alert-success">
                    <strong><Translate value='uploadSuccessGreet'/></strong>{success} <Translate value='uploacSuccess'/>
                </div>}
                <form onSubmit={this.handleSubmit} method="POST">
                    <div className="form-group">
                        <label htmlFor="file"><Translate value='File'/></label>
                        <input type="file" className="form-control" name="file" ref={this.uploadInputRef} onChange={this.handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="file"><Translate value='Password'/></label>
                        <input type="password" name="password" onChange={ this.handleChange } className="form-control" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="file">LT aprašymas</label>
                        <textarea name="lt_description" onChange={ this.handleChange } className="form-control" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="file">EN aprašymas</label>
                        <textarea name="en_description" onChange={ this.handleChange } className="form-control" />
                    </div>
                    <button type="submit" className="btn btn-success"><Translate value='docSubmit'/></button>
                </form>
            </div>
        );
    }
}

export default UploadImage;