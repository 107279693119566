import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from "react-helmet";

class About extends React.Component {

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('universalMixtureTitle')}</title>
                </Helmet>
                <div className="container fullScreen">
                    <div className="col-md-6">
                        <h1 className="raide"><Translate value='universalMixtureTitle'/></h1>
                        <p><Translate value='universalP1'/></p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default About;
