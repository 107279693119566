import React from 'react';
import ProductBox from '../img/produkcija/productBox.png';
import ProductBoxUniversalMixture from '../img/produkcija/productBoxUniversalMixture.png';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from "react-helmet";


class BuyVermicompost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: {
                productName: '',
                productQuantity: '',
            },
        };

        this._handleOrderChange = this._handleOrderChange.bind(this);
    }

    _orderClick() {
        const { lang } = this.props.match.params;
        const { order } = this.state;
        this.props.history.push(`/${lang}/contact`, order);
    }

    _handleOrderChange(e) {
        const productName = e.target.name;
        const productQuantity = e.target.value;
        this.setState({ order: {productName, productQuantity} });
    }

    render() {
        const { lang } = this.props.match.params;
        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('buyVermicompostTitle')}</title>
                    <meta name="description" content={I18n.t('buyVermicompostDescription')} />
                </Helmet>
                <div className="container fullScreen">
                <h1 className="raide"><Translate value='mesParduodam'/></h1>
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <div className="thumbnail transparentBackground">
                            <a target={'_blank'} href={ProductBox}>
                                <img className={'img-thumbnail'} src={ProductBox} alt={'Vermicompost Ekovormas'} width="219px"/>
                            </a>
                                <div className="caption">
                                <h3 className={'zalia_pav'}><Translate value='Biohumusas'/></h3>
                                <label className="radio-inline"><input type="radio" name="buyVermicompost" value={'30'} onChange={this._handleOrderChange} />
                                    30 <Translate value='liters'/>
                                </label>
                                <label className="radio-inline"><input type="radio" name="buyVermicompost" value={'1000'} onChange={this._handleOrderChange} />
                                    1000 <Translate value='liters'/>
                                </label>
                                <br/>
                                <label className={'radio-inline zalia_pav boxPrice'}><Translate value='kuboKaina'/></label>
                                <label className={'radio-inline zalia_pav boxPrice'}><Translate value='kuboKaina'/></label>
                            </div>
                            <div className={'caption'}>
                                <button type="button" onClick={(order) => this._orderClick(order)} className="btn btn-block btn-success orderButton"><Translate value='uzsisakyti'/></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                        <div className="thumbnail transparentBackground">
                            <a target={'_blank'} href={ProductBoxUniversalMixture}>
                                <img className={'img-thumbnail'} src={ProductBoxUniversalMixture} alt={'Universal Mixture Ekovormas'} width="200px"/>
                            </a>
                            <div className="caption">
                                <h3 className={'zalia_pav'}><Translate value='universalusMisinys'/></h3>
                                <label className="radio-inline"><input type="radio" name="buyUniversalMixture" value={'10'} onChange={this._handleOrderChange} />
                                    50 <Translate value='liters'/>
                                </label>
                                <br/>
                                <label className={'radio-inline zalia_pav boxPrice'}><Translate value='kuboKaina'/></label>
                            </div>
                            <div className={'caption'}>
                                <button type="button" onClick={(order) => this._orderClick(order)} className="btn btn-block btn-success orderButton"><Translate value='uzsisakyti'/></button>
                            </div>
                        </div>
                    </div>
                </div>

                <h1 className="raide"><Translate value='musuProdukcija'/></h1>
                <div className="garantuoja">
                    <ul>
                        <li><Translate value='gaminameBiohumusoMis'/></li>
                        <li><Translate value='galimeFasuotaIrNe'/></li>
                        <li><Translate value='parduodameSliekus'/></li>
                        <li><Translate value='gaminameKomposta'/></li>
                    </ul>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default BuyVermicompost
