import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import UploadImage from "./UploadImage";

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            password: '',
            data: {},
            errorText: ''
        }
        this.uploadInputRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleUploadResponseSuccess(data){
        this.setState({
            data,
            errorText: '',
            password: ''
        })
        this.uploadInputRef.current.value = null;
        this.handleSuccessRedirect();
    };

    handleError(errorText) {
        this.setState({errorText})
    };

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        if (name === 'file'){
            this.setState({
                file: target.files[0]
            });
        } else {
            this.setState({
                [name]: target.value
            });
        }
    };

    handleSuccessRedirect() {
        const { lang } = this.props.match.params;
        setTimeout(() => {
            return this.props.history.push(`/${lang}/documents`)
        }, 2000)
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {password, file } = this.state;
        if (!password || !file) {
            this.setState({errorText: 'fillFields'})
            return;
        }
        const formData = new FormData();
        formData.append('password', password);
        formData.append('file', file);
        
        axios({
            method: 'post',
            url: process.env.REACT_APP_LOCAL_PATH_TO_SCRIPT + 'uploadDocument.php',
            headers: { 'Accept': 'application/json',
         },
         data: formData}).then((res) => {
            this.handleUploadResponseSuccess(res.data)
        })
        .catch((err) => {
            this.handleError(err.response.data.Error);
        });
    };

    render() {
        const { lang } = this.props.match.params;
        const {errorText, data, password } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={lang} />
                    <title>{I18n.t('uploadDocumentsTitle')}</title>
                </Helmet>
                <div className="container fullScreen documents">
                <div className="col-md-6">
                    <h1 className="raide"><Translate value='ikeltiDokumentus'/></h1>
                    <form onSubmit={this.handleSubmit} method="POST">
                    {errorText && <div className="alert alert-danger">
                        <strong><Translate value='uploadSorry'/></strong> 
                        <Translate value={errorText}/></div>
                    }
                    {data.fileUploaded && <div className="alert alert-success">
                        <strong><Translate value='uploadSuccessGreet'/></strong> 
                        {data.successFileName} <Translate value='uploacSuccess'/></div>
                    }
                        <div className="form-group">
                            <label htmlFor="file"><Translate value='File'/></label>
                            <input type="file" className="form-control" name="file" id="file" ref={this.uploadInputRef} onChange={this.handleChange}></input>   
                        </div>
                        <div className="form-group">
                            <label htmlFor="file"><Translate value='Password'/></label>
                            <input type="password" name="password" value={ password } onChange={ this.handleChange } 
                            className="form-control" id='password'></input>   
                        </div>
                        <button type="submit" className="btn btn-success"><Translate value='docSubmit'/></button>
                    </form>
                </div>
                <UploadImage />
            </div>
            </React.Fragment>
        )
    }
};

export default Documents;
